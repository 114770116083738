<template>
    <div class="center_main">
        <div class="container">
                <div class="title">
                    <span>企业中心</span>
                    <span class="canClick" @click="$router.push('/box/personCenter')">个人中心<i class="el-icon-arrow-right"></i></span>
                </div>
                <div class="container_body">
                    <div class="company_name">
                        <span class="text">{{defaultData.holder_name}}</span>
                        <myButton
                            @click="editInfo"
                            type="secondary"
                            height="33"
                            width="105">
                            <img slot="img" src="@/assets/img/companyCenter/edit_icon.png" height="14" width="14"/>
                            <span slot="text" style="color: #333333;">编辑信息</span>
                        </myButton>
                    </div>
                    <div class="name_box">
                        <span>姓名：{{defaultData.user_name}}</span>
                        <span v-if="defaultData.role == '1'" class="name_label">主账号</span>
                    </div>
                    <div class="info_box">
                        <div class="info_item">
                            <span class="text">账号数</span>
                            <span v-if="defaultData.son_account_info">{{defaultData.son_account_info[1] + 1}}/{{defaultData.son_account_info[0] + 1}}</span>
                        </div>
                        <div class="line"></div>
                        <div class="info_item">
                            <span class="text">会员版本</span>
                            <span v-if="defaultData.vip_type == '10'">试用会员</span>
                            <span v-if="defaultData.vip_type == '20'">标准会员</span>
                            <span v-if="defaultData.vip_type == '30'">至尊会员</span>
                        </div>
                        <div class="line"></div>
                        <div class="info_item">
                            <span class="text">到期时间</span>
                            <span v-if="defaultData.vip_end_time">{{defaultData.vip_end_time.slice(0,10)}}</span>
                        </div>
                    </div>
                    <div class="options_item">
                        <span class="text">成员列表</span>
                        <el-input size="medium" @keyup.enter.native="getTableData" style="width: 275px; margin-left: 10px; margin-right: 8px" v-model="phone" placeholder="手机号/姓名">
                            <div slot="suffix" class="input_img" @click="getTableData">
                                <img src="@/assets/img/companyCenter/search_icon.png"/>
                            </div>
                        </el-input>
                        <myButton
                            v-if="defaultData.son_account_info && defaultData.son_account_info[0]"
                            @click="addDialog = true"
                            type="primary"
                            height="33"
                            width="80">
                            <span slot="text">新增成员</span>
                        </myButton>
                    </div>
                    <el-table
                        class="scrollbar-class"
                        border
                        style="width: 70%; overflow-y: scroll; max-height: 300px"
                        :data="tableData">
                        <el-table-column
                            prop="user_name"
                            label="姓名">
                        </el-table-column>
                        <el-table-column
                            prop="account"
                            label="手机号">
                        </el-table-column>
                        <el-table-column
                            label="角色">
                            <template slot-scope="scope">
                                <span>{{scope.row.role == '1'?'管理员':'普通账号'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="操作">
                            <template slot-scope="scope">
                                <el-popconfirm
                                    v-if="scope.row.role != '1'"
                                    popper-class="my_pop"
                                    confirm-button-text='确定'
                                    cancel-button-text='取消'
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="deleteRole(scope.row.id)"
                                    :title="'正在移除'+scope.row.user_name+'，是否确定？'">
                                    <div slot="reference">
                                        <span class="canClick">移除</span>
                                    </div>
                                </el-popconfirm>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

        <el-dialog
            :visible.sync="addDialog"
            :before-close="addClose">
            <template slot="title">
                <div>新增成员</div>
            </template>
            <el-form :model="addData" :rules="addRules" ref="addRole" label-width="80px" class="demo-ruleForm">
                <el-form-item label="账号名称" prop="user_name">
                    <el-input size="small" v-model="addData.user_name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                    <el-input size="small" v-model="addData.phone"></el-input>
                </el-form-item>
                <el-form-item label="账号角色" prop="role">
                    <el-select style="width: 100%" size="small" v-model="addData.role" disabled placeholder="请选择账号角色">
                        <el-option
                            v-for="item in roleOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button @click="addClose">取 消</el-button>
                <el-button type="primary" @click="submitAdd('addRole')">确 定</el-button>
              </span>
        </el-dialog>

        <el-dialog
            :visible.sync="editDialog"
            :before-close="editClose">
            <template slot="title">
                <div>编辑信息</div>
            </template>
            <el-form :model="editData" :rules="editRules" ref="editRole" label-width="80px" class="demo-ruleForm">
                <el-form-item label="企业名称" prop="holder_name">
                    <el-input size="small" v-model="editData.holder_name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editClose">取 消</el-button>
                <el-button type="primary" @click="submitEdit">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
    import myButton from "@/components/myButton/myButton";
    import * as api from "@/api/companyCenter"
  export default {
    name: "index",
    components: {
      myButton,
    },
    data() {
      let checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        }
        let telReg = (/^1[3456789]\d{9}$/)
        if(!telReg.test(value)) {
          return callback(new Error('手机号格式错误'));
        }
      }
      let checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('账号名称不能为空'));
        }
        if(value.length > 20 || value.length < 2) {
          return callback(new Error('请输入2~20个字符'));
        }
      }
      let checkCompany = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('企业名称不能为空'));
        }
        if(value.length > 20 || value.length < 2) {
          return callback(new Error('请输入2~20个字符'));
        }
      }
      return {
        phone: '',
        defaultData: {},
        tableData: [],
        addDialog: false,
        editDialog: false,
        addData: {
          user_name: '',
          phone: '',
          role: 1
        },
        editData: {
          holder_name: '',
        },
        roleOption: [{ label: '普通账号', value: 1}],
        addRules: {
          user_name: [
            { required: true, validator: checkName, trigger: 'blur' }
          ],
          phone: [
            {  required: true, validator: checkPhone, trigger: 'blur' }
          ],
        },
        editRules: {
          holder_name: [
            { required: true, validator: checkCompany, trigger: 'blur' }
          ],
        }
      }
    },
    created() {
      this.getHeadData()
      this.getTableData()
    },
    methods: {
      deleteRole(id) {
        let params = {
            id
        }
        let p = new Promise((resolve, reject) => {
          api.del_user_bind(params).then(res => {
            if(res.data.result_code == 0) {

              resolve(res.data.data);
              this.$message({
                type: 'success',
                message: '移除成员成功'
              })
              this.getHeadData()
              this.getTableData()
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      editInfo() {
        this.editData.holder_name = this.defaultData.holder_name
        this.editDialog = true;
      },
      addClose() {
        this.addDialog = false;
        this.$refs['addRole'].resetFields()
      },
      editClose() {
        this.editDialog = false;
        this.$refs['editRole'].resetFields()
      },
      submitAdd() {
        let telReg = (/^1[3456789]\d{9}$/)
        if (this.addData.user_name && this.addData.phone && telReg.test(this.addData.phone)) {
          let p = new Promise((resolve, reject) => {
            api.add_user_bind(this.addData).then(res => {
              if(res.data.result_code == 0) {
                resolve(res.data.data);
                this.$message({
                  type: 'success',
                  message: '成员添加成功'
                })
                this.addClose()
                this.getHeadData()
                this.getTableData()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
          })
          return p;
        } else {
          console.log('error submit!!');
          return false;
        }
      },
      submitEdit() {
        let p = new Promise((resolve, reject) => {
          api.update_holder_name(this.editData).then(res => {
            if(res.data.result_code == 0) {

              resolve(res.data.data);
              this.$message({
                type: 'success',
                message: '更改成功'
              })
              this.editDialog = false;
              this.getHeadData()
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      getTableData() {
        let params = {
            phone: this.phone
        }
        let p = new Promise((resolve, reject) => {
          api.get_son_account_list(params).then(res => {
            if(res.data.result_code == 0) {
              this.tableData = res.data.data.rows
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      getHeadData() {
        let p = new Promise((resolve, reject) => {
          api.get_holder_info().then(res => {
            if(res.data.result_code == 0) {
              this.defaultData = res.data.data
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      }
    }
  }
</script>

<style scoped lang="scss">
.center_main {
    width: 100%;
    height: 100%;
    .header {
        @extend .width-normal;
        @extend .font-normal;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid $cebeff2;
        font-size: $f16;
        color: $c333333;
        text-indent: 20px;
    }
    .container {
            width: calc(100% - 400px);
            min-height: calc(100% - 90px);
            background-color: #FFFFFF;
            border-radius: 5px;
            border: solid 1px #ebeff2;
            margin: 15px auto;
            .title {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                color: #333;
                padding: 18px;
                border-bottom: 1px solid #ebeff2;
            }
            .container_body {
                padding: 23px 20px;
                .company_name {
                    font-size: 18px;
                    color: #333333;
                    margin-top: 2px;
                    display: flex;
                    justify-content: space-between;
                    .text {
                        font-weight: bold;
                    }
                }
                .name_box {
                    font-size: 14px;
                    color: #333333;
                    margin-top: 13px;
                    .name_label {
                        font-size: 12px;
                        color: #ffffff;
                        background-color: #1f81f8;
                        border-radius: 5px;
                        padding: 4px 6px;
                        margin-left: 11px;
                    }
                }
                .info_box {
                    width: 40%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    font-size: 14px;
                    margin-top: 30px;
                    padding-bottom: 20px;
                    .info_item {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: center;
                        .text {
                            color: #999999;
                            margin-bottom: 10px;
                        }
                    }
                    .line {
                        width: 1px;
                        height: 37px;
                        display: inline-block;
                        background-color: #f4f4f4;
                    }
                }
                .options_item {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: #333333;
                    border-top: 1px solid #ebeff2;
                    padding: 18px 0;
                    .input_img {
                        width: 35px;
                        height: 35px;
                        cursor: pointer;
                        img {
                            width: 18px;
                            height: 18px;
                            margin-top: 9px;
                        }
                    }
                }
            }
        }
}
</style>
<style lang="scss">
.center_main .el-table th.is-leaf {
    background-color: #f8fafc;
    color: #666666;
}
.center_main .el-dialog {
    width: 435px;
    border-radius: 5px;
    border: solid 1px #ebeff2;
    .el-dialog__header {
        padding: 20px;
        padding-bottom: 17px;
        font-size: 16px;
        color: #333333;
        border-bottom: 1px solid #ebeff2;
    }
    .el-dialog__body {
        padding: 21px;
        border-bottom: 1px solid #ebeff2;
    }
}
.my_pop .el-popconfirm__main {
    margin: 10px 0;
}
</style>
