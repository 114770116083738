import http from '@/utils/http'
import Qs from "qs";

// 获取企业中心信息
export function get_holder_info(data) {
  return http({
    url: '/u-base/ac-get_holder_info',
    method: 'get',
    params: data
  })
}

//获取成员列表
export function get_son_account_list(data) {
  return http({
    url: '/u-base/ac-get_son_account_list',
    method: 'get',
    params: data
  })
}

//修改用户主体名称(修改资料)
export function update_holder_name(data) {
  return http({
    url: '/u-base/ac-update_holder_name',
    method: 'post',
    data: Qs.stringify(data)
  })
}

//增加子账号
export function add_user_bind(data) {
  return http({
    url: '/u-base/ac-add_user_bind',
    method: 'post',
    data: Qs.stringify(data)
  })
}

//移除子账号
export function del_user_bind(data) {
  return http({
    url: '/u-base/ac-del_user_bind',
    method: 'post',
    data: Qs.stringify(data)
  })
}
